<template>
  <div class="page-login-desktop">
    <Table label="گزارشات آگهی های کاریابی" icon="mdi-format-list-bulleted" :table="table">
      <tr v-for="(item, index) in table.items" :key="index">
        <td class="text-center">{{ index + 1 }}</td>
        <td class="text-center">{{ item.title }}</td>
        <td class="text-center">{{ item.user }}</td>
        <td class="text-center">{{ item.workingTime }}</td>
        <td class="text-center">{{ item.locations }}</td>
        <td class="text-center">{{ item.expertise }}</td>
        <td class="text-center">

          <v-btn x-small @click="item.actions.view" color="main" icon text fab>
            <v-icon>
              mdi-file-document
            </v-icon>
          </v-btn>

          <v-btn x-small @click="item.actions.edit" color="main" icon text fab>
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>


          <v-btn x-small @click="item.actions.comment" icon text fab>
            <v-icon>
              mdi-message-bulleted
            </v-icon>
          </v-btn>


          <v-btn x-small @click="item.actions.delete" color="error" icon text fab>
            <v-icon>
              mdi-trash-can-outline
            </v-icon>
          </v-btn>


        </td>
      </tr>
    </Table>

    <Pagination v-model="filters.skip" :key="filters.skip" :totalItems="table.totalCount" :perPage="filters.take"
                @change="(page) => { fetchItems({ skip: page }) }"/>

    <v-dialog v-model="deleteItem.modal" width="500">
      <v-card>
        <v-card-title class="text-body-1 ">
          <v-icon class="ml-2">
            mdi-information-outline
          </v-icon>
          حذف
        </v-card-title>
        <v-card-text>
          آیا از حذف این آگهی مطمئن هستید ؟
        </v-card-text>

        <v-card-actions class="d-flex align-center justify-space-between">
          <v-btn @click="deleteItem.modal = false" large text color="main">
            لغو
          </v-btn>


          <v-btn :loading="deleteItem.loading" :disabled="deleteItem.loading" @click="destroyItem" elevation="0" large
                 color="error">
            حذف
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>


    <NewMessage @onSubmit="() => {
      newMessage.modal = false;
    }" :messageData="newMessage"/>


    <v-dialog v-model="job.modal">
      <v-card>
        <v-card-title class="w-100 d-flex align-center justify-space-between">
          <div class="font-weight-bold text-body-1 mb-8 ">
            جزییات آگهی
          </div>
          <v-btn
              @click="job.modal=false"
              large color="error" text icon>
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text>
          <v-row type="flex" align="center" justify="center">
            <v-col :cols="12">
              <v-row>
                <v-col class="pa-4" :cols="3" v-for="(item, index) in job.images" :key="index">
                  <a target="_blank" :href="item">
                    <v-img :src="item"/>
                  </a>
                </v-col>
              </v-row>
            </v-col>
            <v-col :cols="6">

              <div class="mb-6">
                <v-icon color="success">
                  mdi-check-circle
                </v-icon>
                <span class="job-name">
                  عنوان شغل مورد نظر :
                </span>
                <span class="job-value">
                  {{ job.name }}
                </span>
              </div>
              <div class="mb-6">
                <v-icon color="success">
                  mdi-check-circle
                </v-icon>
                <span class="job-name">
                  شیفت کاری :
                </span>
                <span class="job-value">
                  {{ job.workingShift }}
                </span>
              </div>
              <div class="mb-6">
                <v-icon color="success">
                  mdi-check-circle
                </v-icon>
                <span class="job-name">
                  ساعات کاری :
                </span>
                <span class="job-value">
                  {{ job.startTime }}
                  تا
                  {{ job.endTime }}
                </span>
              </div>
              <div class="mb-6">
                <v-icon color="success">
                  mdi-check-circle
                </v-icon>
                <span class="job-name">
                  منطقه مورد نظر :
                </span>
                <span class="job-value">
                  {{ job.location }}
                </span>
              </div>
              <div class="mb-6">
                <v-icon color="success">
                  mdi-check-circle
                </v-icon>
                <span class="job-name">
                  تخصص :
                </span>
                <span class="job-value">
                  {{ job.expertise }}
                </span>
              </div>
            </v-col>

            <v-col :cols="6">
              <img style="max-width:100%" src="/img/job.jpg" alt="">
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>


    <v-dialog max-width="600" v-model="comments.modal">
      <v-card>
        <v-card-title class="mb-4 d-flex align-center justify-space-between">
          <div class="d-flex align-center justify-start">
            <v-icon class="ml-2">
              mdi-information-outline
            </v-icon>
            <div class="text-body-1 font-weight-bold">
              لیست گزارشات
            </div>
          </div>
          <v-btn color="error" @click="comments.modal = false" icon fab>
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text class="pa-6">
          <v-simple-table v-if="table.items.length !== 0 && !table.loading">
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-center table-header">
                  #
                </th>
                <th class="text-center table-header">
                  ارسال کننده
                </th>
                <th class="text-center table-header">
                  تاریخ ثبت
                </th>
                <th class="text-center table-header">
                  متن پیام
                </th>
                <th class="text-center table-header">
                  عملیات
                </th>
              </tr>
              </thead>

              <tbody class="table-body">
              <tr v-for="(item, index) in comments.items" :key="index">
                <td class="text-center">{{ index + 1 }}</td>
                <td class="text-center">{{ item.user }}</td>
                <td class="text-center">{{ item.date }}</td>
                <td class="text-center">{{ item.text }}</td>
                <td class="text-center">
                  <v-btn fab icon small color="error" @click="item.actions.delete">
                    <v-icon>
                      mdi-trash-can-outline
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
              </tbody>


            </template>

          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>


    <v-dialog v-model="commentDeleteItem.modal" width="500">

      <v-card>
        <v-card-title class="text-body-1 ">
          <v-icon class="ml-2">
            mdi-information-outline
          </v-icon>
          حذف
        </v-card-title>
        <v-card-text>
          آیا از حذف این نظر مطمئن هستید ؟
        </v-card-text>

        <v-card-actions class="d-flex align-center justify-space-between">
          <v-btn @click="commentDeleteItem.modal = false" large text color="main">
            لغو
          </v-btn>


          <v-btn :loading="commentDeleteItem.loading" :disabled="commentDeleteItem.loading" @click="destroyComment"
                 elevation="0" large color="error">
            حذف
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>


  </div>
</template>

<script>

import Table from "@/components/Table"
import Pagination from "@/components/Pagination"
import {all, destroy} from "@Newfiling/module-job/src/api";
import {AppConfig} from "@Newfiling/services/src";
import NewMessage from "@/components/NewMessage"
import {fetchJobComments, readJobComment} from "../../api";


export default {
  name: 'DesktopLogin',

  components: {
    Table,
    Pagination,
    NewMessage,
  },

  data() {
    return {
      comments: {
        modal: false,
        items: [],
      },
      commentDeleteItem: {
        modal: false,
        loading: false,
        id: null,
      },
      shiftItems: [
        {
          id: 1,
          name: 'صبح تا ظهر',
        },
        {
          id: 2,
          name: 'عصر تا شب',
        },
        {
          id: 3,
          name: 'تمام',
        },
      ],
      filters: {
        modal: false,
        Ajansname: null,
        jobcode: undefined,
        locationsid: "",
        skip: 0,
        take: 10
      },
      table: {
        headers: [
          '#',
          'عنوان',
          'کاربر',
          'ساعات کاری',
          'منطقه',
          'تخصص مورد نظر',
          'عملیات',
        ],
        items: [],
        totalCount: 0,
        loading: false,
      },
      deleteItem: {
        modal: false,
        loading: false,
        id: null,
      },
      job: {
        modal: false,
        images: [],
        name: '',
        workingShift: '',
        startTime: '',
        endTime: '',
        location: '',
        expertise: '',
      },
      locations: [],
      jobList: [],

      newMessage: {
        modal: false,
        phoneNumber: null,
      },
    }
  },

  beforeMount() {
    this.$store.commit('Breadcrumb/setBreadcrumb', [
      {
        text: 'پنل مدیریت نیوفایل',
        disabled: false,
        href: '/panel/dashboard',
      },
      {
        text: 'گزارشات آگهی های کاریابی',
        disabled: true,
        href: '#',
      },
    ])
  },

  mounted() {
    this.$nextTick(async () => {
      const appConfig = (await AppConfig)
      this.locations = appConfig.neighborhoods.map(item => {
        return {
          id: item.Id,
          name: item.NeighborhoodName
        }
      })

      this.jobList = appConfig.joblist.map(item => {
        return {
          id: item.Id,
          name: item.Jobname
        }
      })
      await this.fetchItems();
    });
  },

  methods: {
    async fetchItems(filters = {}) {
      this.filters = {
        ...this.filters,
        ...filters
      }
      this.filters.modal = false;
      this.table.loading = true;
      try {
        const res = (await fetchJobComments({
          ...this.filters,
          skip: this.filters.skip * this.filters.take,
          locationsid: this.filters.locationsid?.id || undefined

        }))?.data || {
          recordcount: 0,
          list: [],
        };

        console.log({res})
        this.table.items = res?.list.map(item => {
          return {
            title: item.title,
            user: item.mobilesender,
            workingTime: this.shiftItems.find(i => i.id === item.shiftwork)?.name + "( " + item.startinghours + ' - ' + item.endtime + " )",
            locations: this.locations.find(i => i.id === item.locationsid)?.name,
            expertise: item.expertise,
            actions: {
              view: () => {
                readJobComment(item.id);
                this.job = {
                  ...this.job,
                  images: [item.image1, item.image2, item.image3, item.image4].filter(item => item),
                  name: this.jobList.find(i => i.id === item.jobcode)?.name,
                  workingShift: this.shiftItems.find(i => i.id === item.shiftwork)?.name,
                  startTime: item.startinghours,
                  endTime: item.endtime,
                  location: this.locations.find(i => i.id === item.locationsid)?.name,
                  expertise: item.expertise,
                  modal: true
                }
              },

              edit: () => {
                this.$router.push('/panel/jobs/edit/' + item.id)
              },

              delete: () => {
                this.deleteItem = {
                  ...this.deleteItem,
                  id: item.id,
                  modal: true,
                  loading: false,
                }
              },
              comment: () => {
                const getCommentText = (comment) => {
                  let text = comment.messagetext;
                  switch (true) {
                    case comment.notresponsive: {
                      return text = text + " (پاسخگو نیست) ";
                    }
                    case comment.workingelsewhere: {
                      return text = text + " (استخدام شده ) ";
                    }
                    case comment.theadiswrong: {
                      return text = text + " (اطلاعات اشتباه) ";
                    }
                  }

                  return text;

                }
                this.comments = {
                  modal: true,
                  items: item.commentlist.map(i => {
                    console.log(getCommentText(i));
                    return {
                      ...i,
                      text: getCommentText(i),
                      user: i.sendername + "(" + i.sendermobile + ")",
                      date: i.solarHistory,
                      actions: {
                        delete: () => {
                          this.commentDeleteItem = {
                            ...this.commentDeleteItem,
                            id: item.id,
                            modal: true,
                            loading: false,
                          }
                        },
                      }
                    }
                  }),
                }
              },
            }
          }
        });
        this.table.totalCount = res?.recordcount;
      } catch (e) {
        console.log(e)
      }
      this.table.loading = false;
    },

    async destroyItem() {
      this.deleteItem.loading = true;
      try {
        await destroy(this.deleteItem.id);
        this.$toast.success('درخواست مورد نظر با موفقیت حذف شد.');
        this.deleteItem = {
          ...this.deleteItem,
          modal: false,
          loading: false,
          id: null,
        }
        await this.fetchItems();
      } catch (e) {
        console.log(e)
      }
      this.deleteItem.loading = false;
    },

    resetFilters() {
      this.filters = {
        ...this.filters,
        modal: false,
        Ajansname: null,
        jobcode: undefined,
        locationsid: undefined,
        skip: 0,
        take: 10
      }
      this.fetchItems();
    },

    destroyComment() {

    },
  }

}
</script>


<style scoped>
.job-name {
  color: rgba(0, 0, 0, 0.7);
  font-weight: bold;
  font-size: 14px;
}

.job-value {
  color: #000;
  font-weight: bold;
  font-size: 16px;
}
</style>
